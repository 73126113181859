import React from "react";
import ReactDOM from "react-dom";
import {QueryClientProvider} from "react-query";

import AlertsProvider from "components/shared/AlertsProvider";
import ErrorBoundary from "components/error/ErrorBoundary";
import GlobalModals from "components/shared/Modal/GlobalModals";
import {getDefaultQueryClient} from "util/query";

const queryClient = getDefaultQueryClient();

function GlobalModalsApp() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AlertsProvider>
          <GlobalModals/>
        </AlertsProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

ReactDOM.render(
  <GlobalModalsApp/>,
  document.getElementById("global-modals"),
);
